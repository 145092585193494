<template>
    <settings-wrapper>
        <template v-slot:child>
            <form
                class="box-border w-60-l w-90 center mt4"
                style="background: #fbfcfe"
                @submit.prevent="handleUpdateOrganization"
            >
                <div class="box-border-bottom pa3 b f4" style="background: white">Edit your business profile</div>
                <div class="w-80-l w-90 center pa3">
                    <div
                        class="flex flex-wrap justify-between items-center mt3"
                        @drop.prevent="handleDragImage"
                        @dragover.prevent
                        @dragenter.prevent
                    >
                        <div class="w-20-l w-100 pb3 tc b f4 pb0-l pointer" @click.prevent="handleClickUpload">
                            <img
                                :src="state?.image || state?.imageUrl || require('@/assets/images/profile-pic.svg')"
                                alt="Org Image"
                                class="h-80-l w-80-l h-60 w-60 rounded-circle"
                                style="object-position: center; object-fit: cover; height: 80px; width: 80px"
                            />
                        </div>
                        <div class="box-border pa4 w-80-l w-100">
                            <span class="dn">
                                <input
                                    ref="imageElRef"
                                    type="file"
                                    name="imageFile"
                                    id="imageFile"
                                    accept="image/*"
                                    @change.prevent="handleSelectImage"
                                />
                            </span>
                            <span class="font-w1 lh-copy pointer" style="color: #132c8c" @click.prevent="handleClickUpload">
                                Upload
                            </span>
                            or drag and drop your company logo PNG, JPG, GIF up to 10MB
                        </div>
                        <div v-if="showPrompt" class="text-danger">
                            Ready to showcase your logo? Please upload a square image, ideally sized at 200px by 200px, and make
                            sure it's under 2MB for the perfect fit.
                        </div>
                    </div>
                    <!-- Default Business Model -->

                    <div class="b pt4 pb2">Default Business Model</div>
                    <div class="flex flex-row">
                        <input class="mr1" type="radio" id="service" value="SERVICE" v-model="state.orgCategory" />
                        <label class="pr2" for="Service">Service</label>
                        <input class="mr1" type="radio" id="product" value="PRODUCT" v-model="state.orgCategory" />
                        <label class="pr2" for="Product">Product</label>
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt4 pb2">Business Name</div>
                        <input
                            type="text"
                            class="pa2 box-border"
                            name=""
                            placeholder="Enter your business name"
                            id="name"
                            v-model="state.name"
                        />
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Address</div>
                        <input
                            type="text"
                            class="pa2 box-border"
                            name=""
                            placeholder="Enter your address"
                            id="address"
                            v-model="state.address"
                        />
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Country</div>
                        <select
                            class="pa2 box-border"
                            id="country"
                            name="country"
                            @change="(value) => onNameChange(value, 'country')"
                        >
                            <option value="" selected>Choose Country</option>
                            <option v-for="country in sortedCountries" :key="country.alpha2Code" :value="country.name.common">
                                {{ country.name.common }}
                            </option>
                        </select>
                    </div>

                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Zip code</div>
                        <input
                            type="text"
                            class="pa2 box-border"
                            name=""
                            placeholder="Enter your Zip code"
                            id="postCode"
                            v-model="state.postCode"
                        />
                    </div>

                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Phone Number</div>
                        <!-- <input
                            type="number"
                            class="pa2 box-border"
                            name=""
                            placeholder="Enter your phone number"
                            id="phoneNumber"
                            v-model="state.phoneNumber"
                        /> -->

                        <vue-tel-input
                            :type="'tel'"
                            :placeholder="'Enter your phone number'"
                            class="box-border w-100"
                            :id="'phone'"
                            :name="'phone'"
                            mode="international"
                            :defaultCountry="'NG'"
                            v-model="state.phoneNumber"
                        ></vue-tel-input>
                    </div>

                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Email</div>
                        <input
                            type="text"
                            class="pa2 box-border"
                            name=""
                            placeholder="Enter your email"
                            id="orgEmail"
                            v-model="state.orgEmail"
                        />
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Bio</div>
                        <input
                            type="text"
                            class="pa2 box-border"
                            name=""
                            placeholder="Say something about your business"
                            id="bio"
                            v-model="state.bio"
                        />
                        <div class="f7 f6-l pt1">Brief description for your profile. URLs are hyperlinked.</div>
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Sector</div>

                        <select id="business" v-model="state.business">
                            <option value="">Select an option</option>
                            <option value="Sole Trader/Business Name">Sole Trader/Business Name</option>
                            <option value="Partnership">Partnership</option>
                            <option value="Limited Liability Company">Limited Liability Company</option>
                            <option value="Non Profit">Non Profit</option>
                        </select>
                    </div>

                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Type Of Business</div>

                        <select id="typeOfBusiness" v-model="state.typeOfBusiness">
                            <option value="Accommodation and Food Services">Accommodation and Food Services</option>
                            <option value="Administrative and Support Services">Administrative and Support Services</option>
                            <option value="Arts and Recreation Services">Arts and Recreation Services</option>
                            <option value="Construction/Builder">Construction/Builder</option>
                            <option value="Creative Arts & Media Production">Creative Arts & Media Production</option>
                            <option value="Education and Training">Education and Training</option>
                            <option value="Farming, forestry and fishing">Farming, forestry and fishing</option>
                            <option value="Fashion">Fashion</option>
                            <option value="Financial services & insurance">Financial services & insurance</option>
                            <option value="Manufacturing">Manufacturing</option>
                            <option value="Medical / Health Care / Community services">
                                Medical / Health Care / Community services
                            </option>
                            <option value="Personal, Beauty, Wellbeing and other services">
                                Personal, Beauty, Wellbeing and other services
                            </option>
                            <option value="Professional Services (e.g. Legal, Accounting, Marketing, Consulting)">
                                Professional Services (e.g. Legal, Accounting, Marketing, Consulting)
                            </option>
                            <option value="Property Operators and Real Estate services">
                                Property Operators and Real Estate services
                            </option>
                            <option value="Public Limited Company">Public Limited Company</option>
                            <option value="Rental & Hiring services (non Real Estate)">
                                Rental & Hiring services (non Real Estate)
                            </option>
                            <option value="Repair and Maintenance (Automotive & Property)">
                                Repair and Maintenance (Automotive & Property)
                            </option>
                            <option value="Retail Trade (Food & Beverage)">Retail Trade (Food & Beverage)</option>
                            <option value="Retail Trade & Ecommerce (Non-Food)">Retail Trade & Ecommerce (Non-Food)</option>
                            <option value="Technology / Telecommunications services">
                                Technology / Telecommunications services
                            </option>
                            <option value="Trades work (e.g. Plumber, Carpenter, Electrician)">
                                Trades work (e.g. Plumber, Carpenter, Electrician)
                            </option>
                            <option value="Transport, Logistics, Postal, Warehousing">
                                Transport, Logistics, Postal, Warehousing
                            </option>
                            <option value="Wholesale Trade">Wholesale Trade</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>

                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Number of staff</div>
                        <input
                            type="number"
                            class="pa2 box-border"
                            name=""
                            placeholder="Enter the number of your staff"
                            id="numOfStaffs"
                            v-model="state.numOfStaffs"
                        />
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Business size</div>
                        <input
                            type="number"
                            class="pa2 box-border"
                            name=""
                            placeholder="0"
                            id="businessSize"
                            v-model="state.businessSize"
                        />
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">RC Number</div>
                        <input
                            type="number"
                            class="pa2 box-border"
                            name=""
                            placeholder="1234565"
                            id="rcNumber"
                            v-model="state.rcNumber"
                        />
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Account Name</div>
                        <input
                            type="text"
                            class="pa2 box-border"
                            name="bankAccountName"
                            placeholder="Enter your account name"
                            id="bankAccountName"
                            v-model="state.bankAccountName"
                        />
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Account Number</div>
                        <input
                            type="text"
                            class="pa2 box-border"
                            name="bankAccountNumber"
                            placeholder="Enter your account number"
                            id="bankAccountNumber"
                            v-model="state.bankAccountNumber"
                        />
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Bank</div>
                        <input
                            type="text"
                            class="pa2 box-border"
                            name="bankName"
                            placeholder="Enter your bank"
                            id="bankName"
                            v-model="state.bankName"
                        />
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Sort Code</div>
                        <input
                            type="text"
                            class="pa2 box-border"
                            name="sortCode"
                            placeholder="Enter youe sort code"
                            id="sortCode"
                            v-model="state.sortCode"
                        />
                    </div>
                    <div class="b pt4 pb2">Default Account Number</div>
                    <div class="flex flex-row">
                        <input class="mr1" type="radio" id="yes" :value="true" v-model="state.isDefault" />
                        <label class="pr2" for="yes">Yes</label>
                        <input class="mr1" type="radio" id="no" :value="false" v-model="state.isDefault" />
                        <label class="pr2" for="no">No</label>
                    </div>
                    <div class="flex flex-column">
                        <div class="b pt3 pb2">Tax Identification Number</div>
                        <input
                            type="text"
                            class="pa2 box-border"
                            name="tin"
                            placeholder="Enter your TIN"
                            id="tin"
                            v-model="state.tin"
                        />
                    </div>
                    <div class="b pt4 pb2">Show Profile Signature on Invoice</div>
                    <div class="flex flex-row">
                        <input class="mr1" type="radio" id="yes" :value="true" v-model="state.showSignature" />
                        <label class="pr2" for="yes">Yes</label>
                        <input class="mr1" type="radio" id="no" :value="false" v-model="state.showSignature" />
                        <label class="pr2" for="no">No</label>
                    </div>
                </div>
                <div class="pa4" style="border-top: 1px solid #e3e8ee">
                    <div class="flex justify-between">
                        <div></div>
                        <div class="flex">
                            <!-- <button class="btn2">Cancel</button> -->
                            <button class="ml3" :disabled="state.disableButton">
                                {{ state?.disableButton ? 'Updating...' : 'Save changes' }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </settings-wrapper>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import SettingsWrapper from '@/views/Settings/widgets/SettingsWrapper'
import axios from 'axios'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

export default {
    name: 'BusinessProfile',
    components: { SettingsWrapper, VueTelInput },
    setup() {
        const imageElRef = ref(null)
        const store = useStore()
        const organization = computed(() => store.state?.Settings?.organization)
        const showPrompt = ref(false)
        const countries = ref([])
        const sortedCountries = ref([])
        const selectedCountry = ref(null)

        const allCountries = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all')
                countries.value = response.data
            } catch (error) {
                console.error('Error fetching countries:', error)
            }
        }
        const sortCountriesAlphabetically = () => {
            sortedCountries.value = [...countries.value].sort((a, b) => a.name.common.localeCompare(b.name.common))
        }

        // Watch for changes in countries and sort when updated
        watch(countries, () => {
            sortCountriesAlphabetically()
        })
        const initialState = () => ({
            disableButton: false,
            name: '',
            address: '',
            country: '',
            postCode: '',
            phoneNumber: '',
            orgEmail: '',
            bio: '',
            sector: '',
            staff: '',
            size: '',
            imageUrl: '',
            imageFile: '',
            image: '',
            imageUrl: '',
            numOfStaff: 0,
            businessSize: 0,
            rcNumber: '',
            orgCategory: '',
        })

        let state
        if (!organization.value) {
            state = reactive(initialState())
        } else {
            state = reactive(organization.value)
        }

        watch(
            () => organization?.value,
            (prev, next) => {
                if (prev !== next) {
                    const data = organization?.value
                    // get default bank details
                    const bankDetails = data?.bankDetails.find((bank) => bank.isDefault === 'true' || bank.isDefault === true)
                    state.bankAccountName = bankDetails?.bankAccountName
                    state.bankAccountNumber = bankDetails?.bankAccountNumber
                    state.bankName = bankDetails?.bankName
                    state.sortCode = bankDetails?.sortCode
                    state.tin = bankDetails?.tin
                    Object.assign(state, reactive(data))
                }
            }
        )

        const handleClickUpload = () => {
            // eslint-disable-next-line
            imageElRef?.value?.click()
        }

        const handleSelectImage = (e) => {
            if (e?.target?.files?.length) {
                const file = e.target.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.imageFile = e.target.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    const img = new Image()
                    img.src = e.target.result
                    img.onload = () => {
                        // this checks if the image is square (200px by 200px)
                        if (img.width === 200 && img.height === 200) {
                            showPrompt.value = false
                        } else {
                            showPrompt.value = true
                            setTimeout(() => {
                                showPrompt.value = false
                            }, 10000)
                        }
                        state.imageFile = file
                        state.imageUrl = e.target.result

                        if (reader.readyState === 2) {
                            showPrompt.value = true
                            state.image = reader.result.toString()
                        }
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        const handleDragImage = (e) => {
            if (e?.dataTransfer?.files?.length) {
                const file = e.dataTransfer.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.imageFile = e.dataTransfer.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    state.imageFile = file
                    state.imageUrl = e.target.result

                    if (reader.readyState === 2) {
                        state.image = reader.result.toString()
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        const handleUpdateOrganization = () => {
            state.disableButton = true

            const formData = new FormData()

            const data = Object.keys(state)

            data.forEach((key) => {
                if (state[key]) formData.append(key, state[key])
            })

            store.dispatch('Settings/updateOrganization', formData).then((resp) => {
                if (resp?.success) {
                    state.disableButton = false
                } else {
                    state.disableButton = false
                }
            })
        }

        const onNameChange = (value, stateName) => {
            state[stateName] = value.target.value
        }

        onMounted(() => store.dispatch('Settings/getOrganization'))
        onMounted(() => allCountries())

        return {
            state,
            handleDragImage,
            imageElRef,
            handleSelectImage,
            handleClickUpload,
            handleUpdateOrganization,
            showPrompt,
            sortedCountries,
            selectedCountry,
            onNameChange,
        }
    },
}
</script>

<style scoped></style>
